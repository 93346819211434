import { defineMessages } from 'react-intl';

const messages = defineMessages({
  title: {
    id: 'productSummary.title',
    defaultMessage: 'Base Product Summary',
  },
  accessories: {
    id: 'productSummary.accessories',
    defaultMessage: 'Accessories',
  },
  total: {
    id: 'productSummary.total',
    defaultMessage: 'Total',
  },
  financingTitle: {
    id: 'productSummary.financingTitle',
    defaultMessage: 'Customer Financing Available',
  },
  financingDescription: {
    id: 'productSummary.financingDescription',
    defaultMessage: 'The customer has pre-qualified for the following financing:',
  },
  financingConfirm: {
    id: 'productSummary.financingConfirm',
    defaultMessage: 'Confirm financing details',
  },
  financingConfirmDisclaimer: {
    id: 'productSummary.financingConfirmDisclaimer',
    defaultMessage: "This will not impact the customer's credit score",
  },
  isEmpty: {
    id: 'productSummary.isEmpty',
    defaultMessage: 'NADA/Invoice value cannot be 0. Please add value.',
  },
  missingValueToastError: {
    id: 'productSummary.missingValueToastError',
    defaultMessage: '{errorField} cannot be empty. Please add a value.',
  },
});

export default messages;
