import EzGoLogo from '@luna-protocol/core/src/assets/ezgo-promo.svg';
import AquaLogo from '@luna-protocol/core/src/assets/formLogo.png';
import Button from '@luna-protocol/core/src/components/Button/Button';
import LineBreak from '@luna-protocol/core/src/components/LineBreak/LineBreak';
import { USDollar } from '@luna-protocol/core/src/utils/constants/format.ts';
import useToast from '@luna-protocol/core/src/utils/useToast.ts';
import { useContext, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { AppContext } from '../../AppContext.tsx';
import { ProductItem } from '../../types';
import EditableInput from '../EditableInput/EditableInput.tsx';
import OfferCard from './OfferCard.tsx';
import messages from './ProductSummary.messages.ts';
import './ProductSummary.scss';
import { buildProductList } from '../../utils/buildProductList.ts';
import { returnImgSrc } from '@luna-protocol/core/src/utils/returnImgSrc.ts';

export interface ProductSummaryProps {
  AddOnTotal?: number;
  editable?: boolean;
  approvedAmount?: number;
  showConfirmButton?: boolean;
  onSubmit?: (products: ProductItem[]) => void;
  ButtonText?: string;
  showFinancing?: boolean;
}

const ProductSummary = ({
  approvedAmount,
  AddOnTotal,
  showConfirmButton = true,
  editable = true,
  ButtonText,
  onSubmit,
  showFinancing = true,
}: ProductSummaryProps) => {
  const { serialNumbers, OEMType, dealerFlowTemplate } = useContext(AppContext);
  const { formatMessage } = useIntl();
  const initialTotal = AddOnTotal ? AddOnTotal + 0 : 0;
  const [runningTotal, setRunningTotal] = useState(initialTotal);
  const [productList, setProductList] = useState<ProductItem[] | undefined>(undefined);
  const [productListString, setProductListString] = useState<string>(JSON.stringify(productList));
  const [disableButton, setDisableButton] = useState(false);

  const { createToast } = useToast();

  useEffect(() => {
    if (productList === undefined && serialNumbers !== undefined) {
      setProductList(buildProductList(serialNumbers, OEMType, dealerFlowTemplate?.base_type));
    }
  }, [serialNumbers, OEMType, setProductList, productList]);

  useEffect(() => {
    if (productList === undefined) return;
    const sumPrices = (productList: ProductItem[]) => {
      const targetItems = productList.filter((product: ProductItem) => product.title.includes('Price'));
      const result = targetItems.reduce((acc: number, obj: ProductItem) => {
        return acc + Number(obj.value);
      }, 0);
      return result;
    };
    const sumTotal = sumPrices(productList);
    setRunningTotal(sumTotal);
  }, [productList, productListString]);

  useEffect(() => {
    if (productList === undefined) return;
    const zeroValues = productList
      .filter(
        (product: ProductItem) =>
          product.title.includes('Model') ||
          product.title.includes('Make') ||
          product.title.includes('Year') ||
          product.title.includes('Number'),
      )
      .filter((product: ProductItem) => !product.value);
    if (zeroValues.length > 0) {
      for (let i = 0; i < zeroValues.length; i++) {
        createToast({
          title: formatMessage(messages.missingValueToastError, {
            errorField: zeroValues[i].title,
          }),
          status: 'error',
        });
      }
      setDisableButton(true);
    }
  }, [productList, productListString, createToast, formatMessage]);

  const handleEditableInput = (_title?: string, value?: string, index?: number) => {
    if (productList === undefined) return;
    if (index === undefined || value === undefined) return;
    const existingItem = productList[index];
    if (existingItem === undefined) return;

    existingItem.value = value || '';
    productList.splice(index, 1, existingItem);
    setProductList(productList);
    setProductListString(JSON.stringify(productList));
  };

  if (productList === undefined) return null;

  return (
    <div className="product-summary">
      <h3>{formatMessage(messages.title)}</h3>
      <div className="product-summary--container">
        {productList.map((item: ProductItem, index) => {
          const { title, value, label } = item;
          return (
            title !== 'Collateral ID' && ( // we don't want to display the collateral ID to the user
              <div key={`${item}-${index}`}>
                <div className="product-summary--row">
                  <div className="product-summary--label">{label || title}</div>
                  <EditableInput
                    editable={title === 'Item' ? false : editable}
                    title={title}
                    index={index}
                    initialValue={value}
                    onValueChange={(title: string, value?: string | number, index?: number) => {
                      handleEditableInput(title, value?.toString(), index);
                    }}
                    selectOptions={item.title.includes('Condition') ? ['New', 'Used'] : undefined}
                  />
                </div>
              </div>
            )
          );
        })}
        <div className="product-summary--row">
          <div className="product-summary--label">{formatMessage(messages.total)}</div>
          <div className="product-summary--item">
            <div className="product-summary--total">
              <p>{USDollar.format(runningTotal)}</p>
            </div>
          </div>
        </div>
      </div>
      {showFinancing && (
        <>
          <LineBreak bordered />
          <h4>{formatMessage(messages.financingTitle)}</h4>
          <p>{formatMessage(messages.financingDescription)}</p>
          <div className="offer-card--container">
            <OfferCard
              img={
                dealerFlowTemplate?.promotional_logo
                  ? returnImgSrc(dealerFlowTemplate.promotional_logo)
                  : OEMType === 'ezgo'
                    ? EzGoLogo
                    : AquaLogo
              }
              title={'Financing offer'}
              offer={USDollar.format(approvedAmount || 0)}
            />
          </div>
          {showConfirmButton ? (
            <Button
              type="submit"
              variant="secondary"
              className="full-width"
              testId="button-add-accessory"
              disabled={disableButton}
              onClick={() => {
                const NadaOrInvoice = productList.find(product => {
                  return product.title.includes('NADA') || product.title.includes('Invoice');
                });
                const isEmpty =
                  NadaOrInvoice?.value === '0.00' || NadaOrInvoice?.value === '0' || NadaOrInvoice?.value === '';

                if (isEmpty) {
                  createToast({
                    title: `${formatMessage(messages.isEmpty)}`,
                    status: 'error',
                  });
                } else {
                  onSubmit && onSubmit(productList);
                }
              }}>
              {ButtonText ? ButtonText : formatMessage(messages.financingConfirm)}
            </Button>
          ) : (
            <LineBreak bordered />
          )}
          <p className="financial-disclaimer">{formatMessage(messages.financingConfirmDisclaimer)}</p>
        </>
      )}
    </div>
  );
};

export default ProductSummary;
